import { useTranslation } from '@sortlist-frontend/translation/ssr';
import { getStorage } from '@sortlist-frontend/utils';
import { Fragment, useEffect, useState } from 'react';

import { BriefingButton } from '_components/Briefing/versions/NormalBriefing/button';
import { Obfuscate } from '_components/common/Obfuscate/Obfuscate';
import { useTracker } from '_core/hooks/use-tracker';
import { agencyProfileConfig } from '_features/agency/profile/agency-profile.config';
import { hasBrandingModule } from '_features/agency/profile/utils/has-branding-module';

type SecondaryCTAProps = {
  agencyWebsite: string | null;
  brandingModule: string | null;
  agencySlug: string;
};

export const SecondaryCTA: React.FC<SecondaryCTAProps> = (props) => {
  const { agencyWebsite, brandingModule, agencySlug } = props;
  const { t } = useTranslation(agencyProfileConfig.i18nNamespaces);
  const { trackUntyped } = useTracker();
  const [sessionEntryUrl, setSessionEntryUrl] = useState('');

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const url = getStorage('sessionEntryUrl') || window.location.href;
      setSessionEntryUrl(url.replace(/"/g, ''));
    }
  }, []);

  const trackOpenWebsite = () => {
    trackUntyped('websiteOpened', { agencySlug, page: 'agency-public-profile', element: 'secondary-cta' });
  };

  return (
    <Fragment>
      {hasBrandingModule(brandingModule) ? (
        <Obfuscate
          className="text-center btn btn-light btn-md btn-primary"
          obfuscate={true}
          role="button"
          href={`${agencyWebsite}?utm_source=sortlist&utm_medium=profile&utm_campaign=${encodeURIComponent(sessionEntryUrl)}`}
          onClick={trackOpenWebsite}
          target="_blank"
          data-testid="openWebsite">
          {t('agency:profile.about.openWebsiteCta')}
        </Obfuscate>
      ) : (
        <BriefingButton
          data-testid="compareAgencies"
          page="agency-profile"
          cta="about-panel"
          truncate={false}
          buttonStyle="primary"
          buttonVariant="light"
          size="md"
          className="text-center"
          label={t('agency:profile.about.compareCta')}
        />
      )}
    </Fragment>
  );
};
