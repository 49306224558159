import { cx } from '@emotion/css';
import { Divider } from '@sortlist-frontend/design-system';
import { ImageSourceHelper } from '@sortlist-frontend/media';
import { useTranslation } from '@sortlist-frontend/translation/ssr';
import { isBrowser, isNonEmptyString } from '@sortlist-frontend/utils';
import dynamic from 'next/dynamic';
import { FC, ForwardedRef, Fragment, MutableRefObject, useEffect, useRef, useState } from 'react';

import { GenericPageSeo } from '_components/seo/generic-page-seo';
import { usePublicAppContext } from '_core/context/public-app-context';
import { useComparedAgencies } from '_core/hooks/use-compared-agencies';
import { useTracker } from '_core/hooks/use-tracker';
import * as S from '_features/agency/profile/agency-profile.styles';
import { BottomBar, SectionsDetails } from '_features/agency/profile/components';
import {
  AboutPanel,
  AwardsPanel,
  ContactPanel,
  HeroPanel,
  ReviewsPanel,
  ServicesPanel,
  SimilarPanel,
  TeamPanel,
} from '_features/agency/profile/sections';
import type { PortfolioPanelProps } from '_features/agency/profile/sections/portfolio/portfolio-panel';
import { JsonLd } from '_features/agency/profile/seo/jsonld/jsonld';
import { hasBrandingModule } from '_features/agency/profile/utils/has-branding-module';

import {
  useGetAgencyAwards,
  useGetAgencyOffices,
  useGetAgencyPortfolio,
  useGetAgencyProfile,
  useGetAgencyReviews,
  useGetAgencySeoDetails,
  useGetAgencyServices,
  useGetAgencyTeam,
} from '../../../core/repos/agency-profile.repo';
import { agencyProfileConfig } from './agency-profile.config';
import {
  getActiveSections,
  getAgencyStats,
  getSeoPageImage,
  getSeoPageTitle,
  type AgencyStats,
  type Section,
} from './utils';

const PortfolioPanel = dynamic<PortfolioPanelProps>(
  () => import('_features/agency/profile/sections').then((mod) => mod.PortfolioPanel),
  { ssr: false },
);
type Props = {
  children?: never;
  scrollableCtn?: ForwardedRef<HTMLDivElement>;
  disableCache?: boolean;
  top: string[] | string | null;
};

const defaultProps = {
  agencySlug: 'capital-panache',
  disableCache: false,
};

const missingAgencyLogo = ImageSourceHelper.fromPath('/_img/default/default-image-square.svg');

export const AgencyProfilePage: FC<Props> = (props) => {
  const { scrollableCtn, disableCache, top } = { ...defaultProps, ...props };
  const { trackUntyped, ready } = useTracker();
  const { locale: localeFromContext, pageData, domainInfo, canonical } = usePublicAppContext();
  const { agencySlug } = pageData as { agencySlug: string };
  const locale = localeFromContext as string;
  const { t } = useTranslation(agencyProfileConfig.i18nNamespaces);
  const [heroSections, setHeroSections] = useState<Section[]>([]);
  const [isStickyBar, setStickyBar] = useState(false);
  const bottomBarLimit = useRef<HTMLDivElement>(null);

  const { data: agencyMainData } = useGetAgencyProfile(agencySlug, locale);
  const { data: awards } = useGetAgencyAwards(agencySlug, locale);
  const { data: reviewsData } = useGetAgencyReviews(agencySlug, locale);
  const { data: seoDetails } = useGetAgencySeoDetails(agencySlug, locale);
  const { data: works } = useGetAgencyPortfolio(agencySlug, locale);
  const { data: services } = useGetAgencyServices(agencySlug, locale);
  const { data: offices } = useGetAgencyOffices(agencySlug, locale);
  const { data: team } = useGetAgencyTeam(agencySlug, locale);

  const bottomBarLimitCurrent = (bottomBarLimit as MutableRefObject<HTMLDivElement>)?.current;
  const scrollableCtnCurrent = (scrollableCtn as MutableRefObject<HTMLDivElement>)?.current;
  const agencyStats: AgencyStats = getAgencyStats(awards, reviewsData, team, works, services);
  const { medias, name: agencyName, plan, slug } = agencyMainData || {};
  const agencyLogoUrl = medias?.logo?.url ?? missingAgencyLogo;
  const showTeamSection = agencyStats.teamMembersCover || agencyStats.teamStory;
  const servicePanelClassName = agencyStats.servicesStats.servicesCount > 0 ? '' : 'hide';
  const portfolioPanelClassName = agencyStats.worksCount > 0 ? '' : 'hide';
  const teamPanelClassName = showTeamSection ? '' : 'hide';
  const awardsPanelClassName = agencyStats.awardsCount > 0 ? '' : 'hide';

  const { comparedAgencies, setComparedAgencies } = useComparedAgencies();

  useEffect(() => {
    // 'agencyImpression' events REQUIRE the parameters: agencyName,
    // agencyPlan (which should be visibility_plan), agencySlug, app, type.
    if (ready && agencyMainData) {
      trackUntyped('agencyImpression', {
        agencyName,
        agencyPlan: plan,
        agencySlug: slug,
        type: 'profile',
      });
    }
  }, [ready, agencyMainData]);

  useEffect(() => {
    setHeroSections(getActiveSections(SectionsDetails, agencyStats, agencyMainData));
  }, [agencyMainData]);

  const handleActivePanel = (section?: Section | undefined) => {
    if (isBrowser()) {
      const { id } = section ?? {};
      location.hash = '';
      if (isNonEmptyString(id)) {
        location.hash = `#${id}`;
      } else {
        window.scrollTo(0, 0);
      }
    }
  };

  const showContact = () => {
    const isOffice = offices && offices.offices?.length > 0;
    const isBrandingFree = agencyMainData?.branding_plan === 'free';

    if (isBrandingFree && isOffice) return true;

    return !isBrandingFree && (isOffice || agencyMainData?.website_url || agencyMainData?.social_data);
  };

  const handleStickyBottomBar = () => {
    const bottomLimit = bottomBarLimitCurrent?.getBoundingClientRect().top;
    const currentBottomPosition = scrollableCtnCurrent?.getBoundingClientRect().bottom;
    const overBottomLimit = currentBottomPosition > bottomLimit;
    const sticky = !overBottomLimit && scrollableCtnCurrent?.scrollTop > 900;
    setStickyBar(sticky);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleStickyBottomBar, true);
    return () => window.removeEventListener('scroll', handleStickyBottomBar, true);
  }, [bottomBarLimitCurrent, scrollableCtnCurrent]);

  if (!agencyMainData) return <Fragment></Fragment>;

  const showSimilarPanel = !hasBrandingModule(agencyMainData?.branding_plan);

  return (
    <Fragment>
      <GenericPageSeo
        canonical={canonical}
        title={getSeoPageTitle(top, agencyMainData?.name, agencyMainData?.locality, agencyStats.reviewsCount, t)}
        description={t('agency:profile.seo.metaDescription', { agencyName: agencyMainData?.name })}
        image={getSeoPageImage(top, medias?.cover)}
      />
      <JsonLd
        agencyStats={agencyStats}
        agency={agencyMainData}
        reviews={reviewsData || null}
        seoDetails={seoDetails || null}
        logo={ImageSourceHelper.fromUrl(agencyLogoUrl, { width: 150 })}></JsonLd>
      <S.View className="layout-xs-fill layout-xs-column">
        {isStickyBar && <BottomBar />}

        <div className="container-lg layout-column px-xs-0 pt-gt-xs-32 pb-gt-sm-64 flex-xs">
          <S.Shape className="bg-neutral-100 shadow-2 rounded-xl flex layout-xs-column">
            <HeroPanel sections={heroSections.filter((section) => section.id !== 'similar')} />
            <AboutPanel
              handleActivePanel={handleActivePanel}
              agencyStats={agencyStats}
              comparedAgencies={comparedAgencies}
              setComparedAgencies={setComparedAgencies}
            />

            <Divider className={cx(servicePanelClassName)} />

            {services && (
              <ServicesPanel
                className={cx(servicePanelClassName)}
                services={services}
                handleActivePanel={handleActivePanel}
                agencyLogoUrl={agencyLogoUrl}
              />
            )}

            <Divider className={cx({ hide: !showSimilarPanel }, 'similar')} />

            {showSimilarPanel && (
              <SimilarPanel
                agencyName={agencyName as string}
                agencySlug={agencySlug}
                disableCache={disableCache}
                locale={locale}
              />
            )}

            <Divider className={cx(portfolioPanelClassName)} />

            {works && (
              <PortfolioPanel
                className={cx(portfolioPanelClassName)}
                agencyLogoUrl={agencyLogoUrl}
                disableCache={disableCache}
              />
            )}
            <Divider className={cx(teamPanelClassName)} />

            <TeamPanel className={cx(teamPanelClassName)} />

            <Divider className={cx(awardsPanelClassName)} />

            {awards && (
              <AwardsPanel
                awardsData={awards}
                className={cx(awardsPanelClassName, 'flex-xs')}
                agencyLogoUrl={agencyLogoUrl}
              />
            )}

            <Divider />

            <ReviewsPanel />

            <div
              className={cx({ hide: !agencyMainData?.website_url && !agencyMainData?.address })}
              ref={bottomBarLimit}>
              <Divider className={cx({ hide: agencyMainData?.plan === 'free' })} />
              {showContact() && <ContactPanel />}
            </div>
          </S.Shape>
        </div>
      </S.View>
    </Fragment>
  );
};
