import { Locale } from 'date-fns';
import { de, enGB, es, fr, it, nl, nlBE } from 'date-fns/locale';

export { differenceInMilliseconds, isPast } from 'date-fns';

export const SECOND_IN_MILLISECONDS = 1000;
export const MINUTE_IN_MILLISECONDS = SECOND_IN_MILLISECONDS * 60;
export const HOUR_IN_MILLISECONDS = MINUTE_IN_MILLISECONDS * 60;
export const DAY_IN_MILLISECONDS = HOUR_IN_MILLISECONDS * 24;
export const MONTH_IN_MILLISECONDS = DAY_IN_MILLISECONDS * 30;
export const YEAR_IN_MILLISECONDS = DAY_IN_MILLISECONDS * 365;

type TimeLabel = {
  secondsLabel: string;
  minutesLabel: string;
  hoursLabel: string;
  daysLabel: string;
  monthsLabel: string;
  yearsLabel: string;
};

type TimeAgoLabelGenerator = (count: number) => TimeLabel;

export const timeAgo = (date: Date, generator: TimeAgoLabelGenerator) => {
  const delta = Date.now() - date.valueOf();
  const count = (unit: number, biggerUnit = 0, biggerCount = 0) =>
    Math.floor((Math.abs(delta) - biggerUnit * biggerCount) / unit);

  const years = count(YEAR_IN_MILLISECONDS);
  const months = count(MONTH_IN_MILLISECONDS, YEAR_IN_MILLISECONDS, years);
  const days = count(DAY_IN_MILLISECONDS, MONTH_IN_MILLISECONDS, months);
  const hours = count(HOUR_IN_MILLISECONDS, DAY_IN_MILLISECONDS, days);
  const minutes = count(MINUTE_IN_MILLISECONDS, HOUR_IN_MILLISECONDS, hours);
  const seconds = count(SECOND_IN_MILLISECONDS, MINUTE_IN_MILLISECONDS, minutes);

  switch (true) {
    case delta < MINUTE_IN_MILLISECONDS:
      return {
        timer: SECOND_IN_MILLISECONDS,
        text: generator(seconds).secondsLabel,
      };
    case delta < HOUR_IN_MILLISECONDS:
      return {
        timer: MINUTE_IN_MILLISECONDS,
        text: generator(minutes).minutesLabel,
      };
    case delta < DAY_IN_MILLISECONDS:
      return {
        timer: HOUR_IN_MILLISECONDS,
        text: generator(hours).hoursLabel,
      };
    case delta < MONTH_IN_MILLISECONDS:
      return {
        timer: DAY_IN_MILLISECONDS,
        text: generator(days).daysLabel,
      };
    case delta < YEAR_IN_MILLISECONDS:
      return {
        timer: DAY_IN_MILLISECONDS,
        text: generator(months).monthsLabel,
      };
    default:
      return {
        timer: DAY_IN_MILLISECONDS,
        text: generator(years).yearsLabel,
      };
  }
};

export const formatCustomDate = (
  date: string | Date | number | undefined,
  options: Intl.DateTimeFormatOptions,
  locale?: string,
) => {
  if (!date) return;
  const dateObj = new Date(date);

  const dateFormatter = new Intl.DateTimeFormat(locale, options);
  return dateFormatter.format(dateObj);
};

export const formatDate = (
  date: string | Date | number | undefined,
  locale?: string,
  extraDay?: number,
  options?: Intl.DateTimeFormatOptions,
) => {
  if (!date) return;
  let dateObj = new Date(date);

  if (extraDay) {
    const newDay = new Date(date).getDate() + extraDay;
    const newDate = new Date(date).setDate(newDay);
    dateObj = new Date(newDate);
  }

  return dateObj.toLocaleDateString(
    locale || 'en',
    options ?? {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    },
  );
};

export const formatDateDay = (date: string | Date, separator = '-'): string => {
  const dateObj = new Date(date);

  const year = dateObj.getFullYear();
  const month = dateObj?.getMonth() + 1 > 9 ? dateObj?.getMonth() + 1 : `0${dateObj?.getMonth() + 1}`;
  const day = dateObj?.getDate() > 9 ? dateObj?.getDate() : `0${dateObj?.getDate()}`;

  return [year, month, day].join(separator);
};

export const isToday = (date: Date) => {
  const today = new Date();

  return (
    date.getDate() == today.getDate() &&
    date.getMonth() == today.getMonth() &&
    date.getFullYear() == today.getFullYear()
  );
};

export const getDateFNSLocale = (locale?: string): Locale => {
  const localesMapped: Record<string, Locale> = {
    en: enGB,
    fr: fr,
    de: de,
    it: it,
    es: es,
    nl: nl,
    nlBE: nlBE,
  };

  if (locale === 'nl-BE') return localesMapped['nlBE'];

  return locale ? localesMapped[locale] || enGB : enGB;
};

export const formatTime = (date: string, locale: string, options?: Intl.DateTimeFormatOptions) => {
  const defaultOptions: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
  };

  return new Date(date).toLocaleTimeString(locale, options ? options : defaultOptions);
};

export const isSameDay = (d1: Date, d2: Date) => {
  if (d1 == null || d2 == null) return;
  return (
    d1?.getFullYear() === d2?.getFullYear() && d1?.getMonth() === d2?.getMonth() && d1?.getDate() === d2?.getDate()
  );
};
