import { cx } from '@emotion/css';
import { Trans, useTranslation } from '@sortlist-frontend/translation/ssr';
import dynamic from 'next/dynamic';

import { DirectBriefingButton } from '_components/Briefing/versions/DirectBriefing/button';
import { CompareButtonProps } from '_components/Comparator/Button';
import { usePublicAppContext } from '_core/context/public-app-context';
import { useComparedAgencies } from '_core/hooks/use-compared-agencies';
import { useGetAgencyProfile } from '_core/repos/agency-profile.repo';
import { agencyProfileConfig } from '_features/agency/profile/agency-profile.config';

import * as S from './bottom-bar.styles';

const CompareButton = dynamic<CompareButtonProps>(
  () => import('_components/Comparator/Button').then((mod) => mod.CompareButton),
  { ssr: false },
);

const defaultSquareImage = '/_img/default/default-image-landscape.svg';

type Props = {
  className?: string;
};

export const BottomBar: React.FC<Props> = (props) => {
  const { className } = props;
  const { t } = useTranslation(agencyProfileConfig.i18nNamespaces);
  const { comparedAgencies, setComparedAgencies } = useComparedAgencies();
  const { locale, pageData, domainInfo, showSnackbar } = usePublicAppContext();
  const { agencySlug } = pageData as { agencySlug: string };
  const { data } = useGetAgencyProfile(agencySlug, locale as string);

  const agencyName = data?.name;
  const logo = data?.medias.logo?.url ?? defaultSquareImage;

  return (
    <S.Shape className={cx(className, 'fixed width-100 py-12 bg-secondary-100 shadow-2')}>
      <div className="container-lg layout-row layout-align-start-center">
        <div className="hide-xs layout-column layout-align-center-start mr-16 flex">
          <span className="small bold">{t('agency:profile.team.cta.title', { name: agencyName })}</span>
          <span className="small">{t('agency:profile.team.cta.subtitle')}</span>
        </div>
        <CompareButton
          showSnackbar={showSnackbar}
          className="flex-xs"
          page="profile-bottom"
          agency={{ logo, slug: agencySlug, name: agencyName }}
          searchContext={undefined}
          comparing={comparedAgencies.some((compared) => agencySlug === compared.slug)}
          onChange={setComparedAgencies}
          comparedAgencies={comparedAgencies}
        />
        <DirectBriefingButton
          page="agency"
          className="mb-16 mt-8 flex-33"
          cta="about-panel"
          truncate={true}
          agencySlug={agencySlug}
          size="md"
          buttonStyle="primary"
          buttonVariant="raised"
          label={<Trans i18nKey={'agency:profile.about.contactCta'}>Contact {{ agencyName }}</Trans>}
        />
      </div>
    </S.Shape>
  );
};
